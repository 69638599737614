import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import PairTable from "@components/table"
import EmbedMap from "@components/map"
import * as Button from "@components/button"
import BreadCrumbs from "@components/breadcrumbs";
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";

const Company = () => (
  <Layout>
    <Meta
      title="事業所一覧"
      desc="岡山、大阪を中心として事業所を設置し、地域のお客様へサービスを提供していきます。"
    />{ }

    <Heading.H1 text="事業所一覧" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "事業内容",
            path: "/business",
          },
          {
            string: "事業所一覧",
          }
        ]}
      />
      <Heading.H3 text="岡山本社" />
      <PairTable values={[
        ['住所', '岡山県岡山市北区田町一丁目4番15号'],
        ['電話番号', '086-225-2291'], 
        ['FAX', '086-225-2250']
      ]} />
      <EmbedMap title="岡山本社" url="https://www.google.com/maps/embed/v1/place?q=%E5%B2%A1%E5%B1%B1%E7%9C%8C%E5%B2%A1%E5%B1%B1%E5%B8%82%E5%8C%97%E5%8C%BA%E7%94%B0%E7%94%BA%E4%B8%80%E4%B8%81%E7%9B%AE4%E7%95%AA15%E5%8F%B7" />
      <Heading.H3 text="大阪支店" />
      <PairTable values={[
        ['住所', '大阪府大阪市中央区北久宝寺町二丁目3番10号'],
        ['電話番号', '06-6262-0971'], 
        ['FAX', '06-6262-0972']
      ]} />
      <EmbedMap title="大阪支店" url="https://www.google.com/maps/embed/v1/place?q=%E5%A4%A7%E9%98%AA%E5%BA%9C%E5%A4%A7%E9%98%AA%E5%B8%82%E4%B8%AD%E5%A4%AE%E5%8C%BA%E5%8C%97%E4%B9%85%E5%AE%9D%E5%AF%BA%E7%94%BA%E4%BA%8C%E4%B8%81%E7%9B%AE3%E7%95%AA10%E5%8F%B7" />
      <Heading.H3 text="今治支店" />
      <PairTable values={[
        ['住所', '愛媛県今治市波止浜１１２番地'],
        ['電話番号', '0898-41-9280'], 
        ['FAX', '0898-41-6692']
      ]} />
      <EmbedMap title="今治支店" url="https://www.google.com/maps/embed/v1/place?q=%E6%84%9B%E5%AA%9B%E7%9C%8C%E4%BB%8A%E6%B2%BB%E5%B8%82%E6%B3%A2%E6%AD%A2%E6%B5%9C%EF%BC%91%EF%BC%91%EF%BC%92%E7%95%AA%E5%9C%B0" />
      <Heading.H3 text="倉敷営業所" />
      <div className="px-4 my-4 flex flex-col space-y-2">
          <Link to="/kurashiki" className="flex items-center">
            <Chevron className="w-3 h-3 text-primary" />
            <span className="text-primary">倉敷営業所が開設しました</span>
          </Link>
      </div>
      <PairTable values={[
        ['住所', '岡山県倉敷市新田1310-1'],
        ['電話番号', '086-425-2371'], 
        ['FAX', '086-421-9758']
      ]} />
      <EmbedMap title="倉敷営業所" url="https://www.google.com/maps/embed/v1/place?q=%E5%B2%A1%E5%B1%B1%E7%9C%8C%E5%80%89%E6%95%B7%E5%B8%82%E6%96%B0%E7%94%B01310-1" />
      <Button.SNSButton title="事業所一覧|木原興業株式会社" url_slag="/branch" />
    </div>
  </Layout>
)

export default Company;